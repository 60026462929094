import React, { useContext } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { translations } from "i18n/translations";
import { MyContext } from "context/mycontext";

export default function How() {
  const { locale } = useContext(MyContext);
  const msg = translations[locale] || translations.en;

  return (
    <>
      <section className="how">
        <div id="anchorhow"></div>
        <Container>
          <div className="d-flex justify-content-center">
            <span className="section-subtitle theme-gradient">
              {msg.howSubtitle}
            </span>
          </div>
          <div className="section-title d-flex justify-content-center">
            {msg.howDoesItWorkTitle}
          </div>

          <Row>
            <Col className="how-col" lg="4">
              <div className="how-steps">
                <span className="step-num step1">{msg.step1}</span>
                <div className="step-title">{msg.step1title}</div>
                <div className="step-subtitle">{msg.step1subtitle}</div>
                <ul>
                  <li>{msg.step1a}</li>
                  <li>{msg.step1b}</li>
                  <li>{msg.step1c}</li>
                  <li>{msg.step1d}</li>
                </ul>
                <div className="d-flex justify-content-center">
                  <Button
                    className="btn-book book-sample rounded-pill"
                    href="https://calendly.com/irenewangcoaching/sample-session"
                    target="_blank"
                  >
                    {msg.btnBookSampleSession}
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="how-col" lg="4">
              <div className="how-steps">
                <span className="step-num step2">{msg.step2}</span>
                <div className="step-title">{msg.step2title}</div>
                <div className="step-subtitle">{msg.step2subtitle}</div>
                <ul>
                  <li>{msg.step2a}</li>
                  <li>{msg.step2b}</li>
                  <li>{msg.step2c}</li>
                  <li>{msg.step2d}</li>
                </ul>
                <div className="d-flex justify-content-center">
                  <Button
                    href="/pricing"
                    className="btn-book book-plan rounded-pill"
                  >
                    {msg.btnPlan}
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="how-col" lg="4">
              <div className="how-steps">
                <span className="step-num step3">{msg.step3}</span>
                <div className="step-title">{msg.step3title}</div>
                <div className="step-subtitle">{msg.step3subtitle}</div>
                <ul>
                  <li>{msg.step3a}</li>
                  <li>{msg.step3b}</li>
                  <li>{msg.step3c}</li>
                  <li>{msg.step3d}</li>
                </ul>
                <div className="d-flex justify-content-center">
                  <Button
                    className="btn-book book-coaching rounded-pill"
                    href="https://calendly.com/irenewangcoaching/60min"
                    target="_blank"
                  >
                    {msg.btnBookCoaching}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
