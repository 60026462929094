export const translations = {
  en: {
    // Nav bar
    brandName: "Irene Wang",
    bookNow: "Book Now",
    slogan1: "Unlock your true potential and live a more fulfilling life.",
    headlineSubtitle: "I'm Irene Wang, your life coach.",
    navHome: "Home",
    navService: "Life Coaching",
    navAbout: "About Me",
    navPricing: "Pricing",
    navEnglish: "English",
    navChinese: "中文",

    // Coaching service
    myServiceSubtitle: "My Service",
    coachingService: "1:1 Life Coaching",
    serviceDescription:
      "I empower people to hear their inner voice, focus on what matters most to them, and make meaningful changes to life a more fulfilling life.",
    // areas
    areasTitle: "The areas I can help you in:",
    areas1: "Career growth",
    areas2: "Personal development",
    areas3: "Interests and passions",
    areas4: "Work life balance",
    areas5: "Productivity",
    areas6: "Confidence and self-belief",
    areas7: "Motherhood and Pregnancy",
    areas8: "Dating and relationship",
    areas9: "Stress reduction",
    areas10: "Self-love",

    // How does it work
    howSubtitle: "Make me your coach",
    howDoesItWorkTitle: "How does it work?",
    step1: "Step 1",
    step2: "Step 2",
    step3: "Step 3",

    step1title: "Try a 1-dollar sample session",
    step1subtitle: "In this session, we will:",
    step1a: "Get to know each other.",
    step1b: "Discuss your goals and expectations",
    step1c: "Have a mini coaching session to try out my coaching style.",
    step1d: "Answer any questions you may have about the coaching process.",

    step2title: "Setup schedule and plan",
    step2subtitle: "Together, we will determine:",
    step2a: "The frequency of our sessions (e.g. weekly, biweekly, monthly).",
    step2b:
      "The method of communication (e.g. video meetings, phone calls, in-person).",
    step2c: "The duration of our coaching journey (3 months minimum).",
    step2d: "The support in-between sessions.",

    step3title: "Attend coaching sessions",
    step3subtitle: "During these 60-minute sessions:",
    step3a:
      "We will have an 1 on 1 conversation where everything kept confidential.",
    step3b:
      "I will ask you thought-provoking questions and assist you in discovering new insights about yourself and exploring different perspectives.",
    step3c:
      "Together, we will co-create action plans and build accountabilities for achieving your goals.",
    step3d: "Be open and honest with yourself to get the most out of it.",

    btnBookSampleSession: "Book Sample Session",
    btnPlan: "See Pricing",
    btnBookCoaching: "Book Full Session",

    // Coach is and not
    whatSubtitle: "What is life coaching",
    whatToExpect: "What to expect with life coaching:",
    coachingIs: "Coaching is:",
    coachingIsNot: "Coaching is not:",
    coachingIsPoint1:
      "A dedicated time and space to help you identify and achieve your personal and professional goals.",
    coachingIsPoint2:
      "A safe and confidential environment where you can fully express and discover your authentic self.",
    coachingIsPoint3:
      "A supportive and non-judgmental environment to help you navigate your emotions and develop coping strategies",
    coachingIsPoint4:
      "I work with you to develop strategies and action plans to help you reach your goals.",
    coachingIsNotPoint1: "Providing therapy or counseling.",
    coachingIsNotPoint2:
      "Tell you what to do or imposing the coach's beliefs on the coachee.",
    coachingIsNotPoint3:
      "Providing quick fixes or promising unrealistic results.",
    coachingIsNotPoint4: "Diagnose mental health conditions.",

    // Pricing
    pricingSubtitle: "My budget plan",
    pricingTitle: "Pricing",
    bestFor: "Best for: ",
    include: "Includes:",
    popularPlan: "Most Popular",
    discountSubtitle: "Discounts",
    discountDetail1: "I believe in making coaching accessible to everyone. ",
    discountDetail2:
      "Special discounts to referrals, students and low-income families.",
    discountDetail3:
      "Contact me to inquire about any special rates that may be available to you.",
    discountBtn: "Get discount",
    samplePlan: "1-dollar sample session",
    samplePlanBestFor: "First timers",
    samplePlanDetail1: "30-minute sample session",
    samplePlanDetail2: "Get to know each other",
    samplePlanDetail3:
      "Mini coaching session to get a taste of my coaching style",
    samplePlanDetail4: "Answer your questions about coaching",
    samplePlanPriceP2: "Sample session",
    samplePlanBtn: "Book Now",
    oneTimePlan: "Single session",
    oneTimePlanBestFor:
      "If you are looking for a one-time boost or a trial session to see if coaching is right for you.",
    oneTimePlanPriceP2: "One Full Session",
    oneTimePlanDetail1: "One full hour coaching session",
    oneTimePlanBtn: "Register",
    fourTimePlan: "4-session package",
    fourTimePlanBestFor:
      "If you need a short-term focused goal setting and accountability.",
    fourTimePlanPriceP2: "=100 USD per session",
    fourTimePlanDetail1: "4 full hour coaching sessions",
    fourTimePlanDetail2:
      "Homework assignment / inquires to deepen the learning",
    fourTimePlanDetail3:
      "Unlimited email support in-between sessions to keep you on track",
    fourTimePlanDetail4: "2 months access to recordings",
    sixTimePlan: "6-session package",
    sixTimePlanBestFor:
      "If you want to make long-lasting change and look for deeper exploration and implementation.",
    sixTimePlanPriceP2: "=85 USD per session",
    sixTimePlanDetail1: "6 full hour coaching sessions",
    sixTimePlanDetail2: "Homework assignment / inquires to deepen the learning",
    sixTimePlanDetail3:
      "Unlimited email support in-between sessions to keep you on track",
    sixTimePlanDetail4: "3 months access to recordings",

    twelveTimePlan: "12-session package",
    twelveTimePlanBestFor:
      "If you are committed to making significant progress and are willing to invest in yourself for maximum results over a longer period.",
    twelveTimePlanPriceP2: "=75 USD per session",
    twelveTimePlanDetail1: "12 full hour coaching sessions",
    twelveTimePlanDetail2:
      "Homework assignment / inquires to deepen the learning",
    twelveTimePlanDetail3:
      "Unlimited email support in-between sessions to keep you on track",
    twelveTimePlanDetail4: "6 months access to recordings",
    priceMayChange:
      "* Please note that our pricing is subject to change and may increase in the future.",

    // Footer
    myName: "Irene Wang",
    lifeCoach: "Life Coaching",
    footerContact: "Contact:",
    linkedin: "Linkedin",
    wechat: "WeChat",
    email: "E-mail",
    calendar: "Calendar",
    location: "Location:",
    city: "Seattle, WA, USA",
    timeZone: "Time Zone:",
    pst: "Pacific Standard Time (PST)",
    cp: "Irene Wang © 2023",
    rights: "All Rights Reserved",

    // Wechat Modal
    wechatModalTitle: "Add Me on WeChat",
    wechatModalP1: "Search by id: leafinsummer88",
    wechatModalP2: "Or scan the QR code.",
    wechatModalP3: "Add friend with note: Life Coaching.",
    close: "Close",

    // Payment Modal
    paymentModalTitle: "Pay to Lock Your Rate",
    amount: "Amount: ",
    paymentModalP1: "Pay through your preferred payment method:",
    paymentModalP2:
      "After the payment, you will be able to book full coaching sessions on my calendar.",
    venmo: "Venmo",
    venmoPaymentP1:
      "Find me on Venmo by id: @yuye-wang, or Scan the QR code below:",
    wechatPaymentP1: "Add me as friend on WeChat and then send payment.",
    wechatPaymentP2:
      " Add friend by id: leafinsummer88, or Scan the QR code below:",
    paypal: "Paypal",
    paypalPaymentP1: "Use Paypal, send through payment  to:",
    paypalPaymentP2: "Email: leaves1991@gmail.com",
    paypalPaymentP3: "Name: Yuye Wang",
    otherPayment: "Other",
    otherPaymentP1: "If you wish to split payments,",
    otherPaymentP2:
      "Or usign other payment methods like Zelle, Alipay, cryptocurrency, etc. ",
    otherPaymentP3: "Please contact me directly.",
    usd: "USD",
    dollarSign: "$",

    // About
    aboutSubtitle: "About Me",
    aboutTitle: "Meet Me in a Nutshell",
    aboutProfessional: "Professional",
    aboutPersonal: "Personal",
    aboutLifecoach: "Life Coaching",
    meIntro:
      "I'm Irene Wang, aka '王雨叶' (Yuye) which means rain and leaf in Chinese.",
    me1: "9 years of experience in the tech industry, at companies such as Google, Microsoft, and Tencent.",
    me2: "Career progression from software engineering to product management",
    me3: "Tech-savvy and well-informed about the latest developments in Silicon Valley, including AI, cryptocurrency, gaming, and software development.",
    me4: "Alumni of Yale University and UW-Madison.",
    me5: "Mother to two young children while managing a demanding career.",
    me6: "Passionate about side projects in areas of design, animation, and vlogging.",
    me7: "Personal experience navigating the cultural transition from China to the United States and embracing diversity.",
    me8: "Formally trained as a Co-Active life coach.",
    me9: "I feel fulfilled as help people improve their lives through coaching.",
  },
  zh: {
    // Nav bar
    brandName: "Irene Wang",
    bookNow: "马上预约",
    slogan1: "释放你的潜能，活出自我，活出精彩！",
    headlineSubtitle: "我是王雨叶Irene，你的人生教练。",
    navHome: "主页",
    navService: "教练服务",
    navAbout: "我是谁",
    navPricing: "价格",
    navEnglish: "English",
    navChinese: "中文",

    // Coaching service
    myServiceSubtitle: "我的服务",
    coachingService: "一对一教练服务",
    serviceDescription:
      "我让人们听到内心的声音，专注于对自己重要的事情，并且为活出自己想要的人生做出实际的改变。",
    // areas
    areasTitle: "我擅长的方向：",
    areas1: "职业发展",
    areas2: "个人成长",
    areas3: "兴趣爱好",
    areas4: "生活和工作的平衡",
    areas5: "提升效率",
    areas6: "自信",
    areas7: "新手爸妈的烦恼",
    areas8: "约会和亲密关系",
    areas9: "减轻压力",
    areas10: "学会爱自己",

    // How does it work
    howSubtitle: "开始教练",
    howDoesItWorkTitle: "3步轻松开始你的教练旅程",
    step1: "第一步",
    step2: "第二步",
    step3: "第三步",

    step1title: "参加1美元教练体验",
    step1subtitle: "这次对话中：",
    step1a: "我们会互相认识彼此",
    step1b: "讨论你的目标和期待",
    step1c: "体验一段迷你教练对话来了解我的教练风格",
    step1d: "解答你关于人生教练的问题",

    step2title: "设定计划",
    step2subtitle: "我会为您定制：",
    step2a: "教练对话的频率，比如：一周一次，两周一次等",
    step2b: "对话方式，比如通过视频、语音或线下见面",
    step2c: "陪伴时长，通常3个月起",
    step2d: "线下支持方式，比如：邮件、短信、微信",

    step3title: "定期和教练对话",
    step3subtitle: "在每次60分钟的教练对话中：",
    step3a: "我会对我们一对一的谈话内容严格保密，保护你的隐私。",
    step3b: "我会通过提问激发你思考，引导你对自我有新的认知，探索不同的视角。",
    step3c: "我们会共同制定行动计划，并且设立督促机制.",
    step3d: "诚实、敞开心扉，才能得到最大收获。",

    btnBookSampleSession: "体验一下",
    btnPlan: "了解价格",
    btnBookCoaching: "预约教练对话",

    // Coach is and not
    whatSubtitle: "什么是人生教练",
    whatToExpect: "人生教练可以为你带来什么？",
    coachingIs: "人生教练是：",
    coachingIsNot: "人生教练不是：",
    coachingIsPoint1: "帮助你寻找方向、实现个人的和事业上的目标。",
    coachingIsPoint2: "一个安全保密的环境，让你充分的表达和发现真实的自己。",
    coachingIsPoint3: "支持你，包容你的情绪、不带评判的引导你找到应对策略。",
    coachingIsPoint4: "和你共同创造、制定行动计划来帮你完成目标。",
    coachingIsNotPoint1: "不是心理咨询，不是心理医生",
    coachingIsNotPoint2:
      "不会直接告诉你要做什么，不会把教练的想法强加在客户身上。",
    coachingIsNotPoint3: "不会帮你快速解决问题，不会承诺不切实际的成果。",
    coachingIsNotPoint4: "不会帮你诊断心理或健康状况",

    // Pricing
    pricingSubtitle: "总有一款适合我",
    pricingTitle: "人生教练价格",
    bestFor: "合适人群",
    include: "核心权益",
    popularPlan: "最热",
    discountSubtitle: "限时折扣",
    discountDetail1: "我希望世界上每一个人都可以有被教练的机会。",
    discountDetail2: "我给好友推荐、学生、以及低收入家庭提供限时优惠。",
    discountDetail3: "看看你是否可以享受优惠价格，请直接和我联系。",
    discountBtn: "我要折扣",

    samplePlan: "首次体验",
    samplePlanBestFor: "第一次了解",
    samplePlanDetail1: "30分钟的教练对话体验",
    samplePlanDetail2: "互相了解",
    samplePlanDetail3: "迷你教练对话，体验我的教练风格",
    samplePlanDetail4: "解答你对人生教练的问题",
    samplePlanPriceP2: "首次超低价体验",
    samplePlanBtn: "马上预约",

    oneTimePlan: "单次教练对话",
    oneTimePlanBestFor: "如果你急需一次情绪解压，或尝试一次自我探索",
    oneTimePlanPriceP2: "单次教练对话",
    oneTimePlanDetail1: "1次完整的60分钟教练对话",
    oneTimePlanBtn: "现在购买",

    fourTimePlan: "4次对话套餐",
    fourTimePlanBestFor: "适用于短期小目标的制定和执行",
    fourTimePlanPriceP2: "平均每次$100",
    fourTimePlanDetail1: "4次完整的60分钟教练对话",
    fourTimePlanDetail2: "课后任务以及课后思考帮你加深学习",
    fourTimePlanDetail3: "提供线下邮件/短信支持",
    fourTimePlanDetail4: "2个月的录音回顾",

    sixTimePlan: "6次对话套餐",
    sixTimePlanBestFor: "做出持久的改变并寻求更深入的探索和实施",
    sixTimePlanPriceP2: "平均每次$85",
    sixTimePlanDetail1: "6次完整的60分钟教练对话",
    sixTimePlanDetail2: "课后任务以及课后思考帮你加深学习",
    sixTimePlanDetail3: "提供线下邮件/短信支持",
    sixTimePlanDetail4: "3个月的录音回顾",

    twelveTimePlan: "6次对话套餐",
    twelveTimePlanBestFor: "愿意投资自己做出飞跃性改变，优化长期稳定的个人成长",
    twelveTimePlanPriceP2: "平均每次$75",
    twelveTimePlanDetail1: "12次完整的60分钟教练对话",
    twelveTimePlanDetail2: "课后任务以及课后思考帮你加深学习",
    twelveTimePlanDetail3: "无限量线下邮件/短信支持",
    twelveTimePlanDetail4: "6个月的录音回顾",
    priceMayChange:
      "* 请注意我们的价格之后可能会不定期上调，现在购买立即锁定你的价格",

    // Footer
    myName: "Irene Wang",
    lifeCoach: "人生教练",
    footerContact: "联系方式:",
    linkedin: "领英",
    wechat: "微信",
    email: "邮箱",
    calendar: "预约日历",
    location: "地址:",
    city: "美国西雅图",
    timeZone: "时区：",
    pst: "美国太平洋时间(PST)",
    cp: "Irene Wang © 2023",
    rights: "All Rights Reserved",

    // Wechat Modal
    wechatModalTitle: "微信添加好友",
    wechatModalP1: "打开微信，搜索微信id: leafinsummer88",
    wechatModalP2: "或扫一扫以下二维码。",
    wechatModalP3: "加好友请备注：教练",
    close: "关闭",

    // Payment Modal
    paymentModalTitle: "支付购买",
    amount: "金额：: ",
    paymentModalP1: "请选择你的支付方式：",
    paymentModalP2: "完成支付后，可以预约60分钟的完整教练对话及服务。",
    venmo: "Venmo",
    venmoPaymentP1: "打开Venmo通过搜索id: @yuye-wang, 或扫一扫以下二维码:",
    wechatPaymentP1: "请在微信上加我为好友，然后转账支付。",
    wechatPaymentP2: "打开微信，搜素id: leafinsummer88,或扫一扫以下二维码:",
    paypal: "Paypal",
    paypalPaymentP1: "打开Paypal，支付到:",
    paypalPaymentP2: "邮箱: leaves1991@gmail.com",
    paypalPaymentP3: "姓名: Yuye Wang",
    otherPayment: "其他方式",
    otherPaymentP1: "如果你想分期付款，",
    otherPaymentP2: "或者用其他支付方式，比如： Zelle, 支付宝, 加密货币等. ",
    otherPaymentP3: "请直接和我联系。",
    usd: "美元",
    dollarSign: "$",

    // About
    aboutSubtitle: "自我介绍",
    aboutTitle: "1分钟认识我",
    aboutProfessional: "职业上：",
    aboutPersonal: "生活上：",
    aboutLifecoach: "人生教练：",
    meIntro: "我是王雨叶，大家也叫我 Irene Wang.",
    me1: "9年互联网行业经历，曾就职于谷歌、腾讯、微软。",
    me2: "从程序员成功转行为产品经理",
    me3: "密切关注硅谷前沿技术，包括人工智能、加密货币、游戏、软件等。",
    me4: "毕业于美国耶鲁大学",
    me5: "是两个小宝宝的妈妈，注重家庭，也兼顾事业发展",
    me6: "对热爱的东西，业余尝试过做：平面设计，小动画，视频",
    me7: "从中国到美国的10年多时间，适应并且拥抱不同的文化。",
    me8: "接受专业训练成为一名共创式人生教练。",
    me9: "通过教练的方式给更多人带来正向的改变让我自己感到很幸福",
  },
};
