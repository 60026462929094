import React, { useContext } from "react";
import MyNavbar from "components/MyNavbar";
import About from "components/About";
import Footer from "components/Footer";
import { Col, Container, Row, Button } from "reactstrap";
import { translations } from "i18n/translations";
import { MyContext } from "context/mycontext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import How from "components/How";

// Home Page
export default function HomePage() {
  const { locale } = useContext(MyContext);
  const msg = translations[locale] || translations.en;

  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);

  return (
    <>
      <div id="anchorhome"></div>
      <MyNavbar />
      <div className="main">
        <section className="headline">
          <Container>
            <Row>
              <Col md="1"></Col>
              <Col md="6">
                <div className="slogan">{msg.slogan1}</div>
                <div className="headline-sub">{msg.headlineSubtitle}</div>
                <div>
                  <Button
                    className="btn-book rounded-pill"
                    href="https://calendly.com/irenewangcoaching"
                    target="_blank"
                  >
                    {msg.bookNow}
                  </Button>
                </div>
              </Col>
              <Col md="4">
                <div className="headline-img"></div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="service">
          <div id="anchorservice"></div>
          <Container>
            <Row>
              <Col md="4">
                <div className="service-img"></div>
              </Col>
              <Col md="8">
                <span className="section-subtitle theme-gradient">
                  {msg.myServiceSubtitle}
                </span>
                <div className="section-title">{msg.coachingService}</div>
                <div className="details">{msg.serviceDescription}</div>
                <Row>
                  <div className="section-subtitle2">{msg.areasTitle}</div>
                  <Col md="4">
                    <ul className="areas">
                      <li>{msg.areas1}</li>
                      <li>{msg.areas2}</li>
                      <li>{msg.areas3}</li>
                      <li>{msg.areas4}</li>
                      <li>{msg.areas5}</li>
                    </ul>
                  </Col>
                  <Col md="5">
                    <ul className="areas">
                      <li>{msg.areas6}</li>
                      <li>{msg.areas7}</li>
                      <li>{msg.areas8}</li>
                      <li>{msg.areas9}</li>
                      <li>{msg.areas10}</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <How />

        <section className="what">
          <Container>
            <div className="d-flex justify-content-center">
              <span className="section-subtitle theme-gradient">
                {msg.whatSubtitle}
              </span>
            </div>
            <div className="section-title d-flex justify-content-center">
              {msg.whatToExpect}
            </div>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <div className="section-subtitle2">{msg.coachingIs}</div>
                <div>
                  <div>
                    <FontAwesomeIcon icon={faCheck} className="icon-check" />
                    {msg.coachingIsPoint1}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheck} className="icon-check" />
                    {msg.coachingIsPoint2}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheck} className="icon-check" />
                    {msg.coachingIsPoint3}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheck} className="icon-check" />
                    {msg.coachingIsPoint4}
                  </div>
                </div>
                <div className="section-subtitle2">{msg.coachingIsNot}</div>
                <div>
                  <div>
                    <FontAwesomeIcon icon={faXmark} className="icon-cross" />
                    {msg.coachingIsNotPoint1}
                  </div>

                  <div>
                    <FontAwesomeIcon icon={faXmark} className="icon-cross" />
                    {msg.coachingIsNotPoint2}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faXmark} className="icon-cross" />
                    {msg.coachingIsNotPoint3}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faXmark} className="icon-cross" />
                    {msg.coachingIsNotPoint4}
                  </div>
                </div>
              </Col>
              <Col md="2"></Col>
            </Row>
          </Container>
        </section>
        <About />

        {/* <section className="testimonial"></section> */}
      </div>
      <Footer />
    </>
  );
}
