import React, { useState, useContext } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { translations } from "i18n/translations";
import { MyContext } from "context/mycontext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAppleWhole,
  faUser,
  faHeart,
  faFire,
  faComment,
  faCircleQuestion,
  faClipboardCheck,
  faEnvelope,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import venmoQR from "images/yuye_venmo_qr.jpg";
import wechatPayQR from "images/yuye_wechat_pay_qr.jpg";

export default function Pricing() {
  const { locale } = useContext(MyContext);
  const msg = translations[locale] || translations.en;

  /* payment methods:
  VENMO;
  PAYPAL;
  WECHAT;
  OTHER;
  */
  const [paymentMethod, setPaymentMethod] = useState("VENMO");
  const [payNum, setPayNum] = useState(0);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const samplePlanPrice = 1;
  const oneTimePlanPrice = 150;
  const fourTimePlanPrice = 400;
  const sixTimePlanPrice = 500;
  const twelveTimePlanPrice = 900;

  function togglePaymentModal(valToPay) {
    // When close modal, clear payment number.
    if (!!isPaymentModalOpen) {
      setPayNum(0);
    } else {
      setPayNum(valToPay);
    }
    setIsPaymentModalOpen(!isPaymentModalOpen);
  }

  return (
    <>
      <section className="pricing">
        <div id="anchorpricing"></div>

        <Container>
          <Row>
            <Col md="1"></Col>
            <Col md="10">
              <div className="d-flex justify-content-center">
                <span className="section-subtitle theme-gradient">
                  {msg.pricingSubtitle}
                </span>
              </div>
              <div className="section-title d-flex justify-content-center">
                {msg.pricingTitle}
              </div>
              <div className="pricing-card sample-session">
                <Row>
                  <Col md="9">
                    <div className="subtitle">{msg.samplePlan}</div>
                    <div className="best">
                      <span className="best-who">{msg.samplePlanBestFor}</span>
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faComment} />
                      {msg.samplePlanDetail1}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faUser} />
                      {msg.samplePlanDetail2}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faAppleWhole} />
                      {msg.samplePlanDetail3}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faCircleQuestion} />
                      {msg.samplePlanDetail4}
                    </div>
                  </Col>
                  <Col
                    md="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <div className="price-num d-flex justify-content-center">
                        {msg.dollarSign + samplePlanPrice}
                      </div>
                      <div className="price-avg d-flex justify-content-center">
                        {msg.samplePlanPriceP2}
                      </div>
                      <Button
                        className="btn-sampleplan rounded-pill d-flex justify-content-center"
                        href="https://calendly.com/irenewangcoaching/sample-session"
                        target="_blank"
                      >
                        {msg.samplePlanBtn}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="pricing-card single-session">
                <Row>
                  <Col md="9">
                    <div className="subtitle">{msg.oneTimePlan}</div>
                    <div className="best">
                      <span className="best-who">{msg.oneTimePlanBestFor}</span>
                    </div>
                    <div className="include">{msg.include}</div>
                    <div className="details">
                      <FontAwesomeIcon icon={faComment} />
                      {msg.oneTimePlanDetail1}
                    </div>
                  </Col>
                  <Col
                    md="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <div className="price-num d-flex justify-content-center">
                        {msg.dollarSign + oneTimePlanPrice}
                      </div>
                      <div className="price-avg d-flex justify-content-center">
                        {msg.oneTimePlanPriceP2}
                      </div>
                      <Button
                        className="btn-onetime rounded-pill d-flex justify-content-center"
                        onClick={() => togglePaymentModal(oneTimePlanPrice)}
                      >
                        {msg.oneTimePlanBtn}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="pricing-card four-session">
                <Row>
                  <Col md="9">
                    <div className="subtitle">{msg.fourTimePlan}</div>
                    <div className="best">
                      <span className="best-who">
                        {msg.fourTimePlanBestFor}
                      </span>
                    </div>
                    <div className="include">{msg.include}</div>
                    <div className="details">
                      <FontAwesomeIcon icon={faComment} />
                      {msg.fourTimePlanDetail1}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faClipboardCheck} />
                      {msg.fourTimePlanDetail2}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faEnvelope} />
                      {msg.fourTimePlanDetail3}
                    </div>
                  </Col>
                  <Col
                    md="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <div className="price-num d-flex justify-content-center">
                        {msg.dollarSign + fourTimePlanPrice}
                      </div>
                      <div className="price-avg d-flex justify-content-center">
                        {msg.fourTimePlanPriceP2}
                      </div>

                      <Button
                        className="btn-fourtime rounded-pill d-flex justify-content-center"
                        onClick={() => togglePaymentModal(fourTimePlanPrice)}
                      >
                        {msg.oneTimePlanBtn}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="pricing-card six-session">
                <Row>
                  <Col md="9">
                    <div className="subtitle">
                      {msg.sixTimePlan}
                      <span className="popular">{msg.popularPlan}</span>
                    </div>
                    <div className="best">
                      <span className="best-who">{msg.sixTimePlanBestFor}</span>
                    </div>
                    <div className="include">{msg.include}</div>
                    <div className="details">
                      <FontAwesomeIcon icon={faComment} />
                      {msg.sixTimePlanDetail1}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faClipboardCheck} />
                      {msg.sixTimePlanDetail2}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faEnvelope} />
                      {msg.sixTimePlanDetail3}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faVideo} />
                      {msg.sixTimePlanDetail4}
                    </div>
                  </Col>
                  <Col
                    md="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <div className="price-num d-flex justify-content-center">
                        {msg.dollarSign + sixTimePlanPrice}
                      </div>
                      <div className="price-avg d-flex justify-content-center">
                        {msg.sixTimePlanPriceP2}
                      </div>

                      <Button
                        className="btn-sixtime rounded-pill d-flex justify-content-center"
                        onClick={() => togglePaymentModal(sixTimePlanPrice)}
                      >
                        {msg.oneTimePlanBtn}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="pricing-card twelve-session">
                <Row>
                  <Col md="9">
                    <div className="subtitle">{msg.twelveTimePlan}</div>
                    <div className="best">
                      <span className="best-who">
                        {msg.twelveTimePlanBestFor}
                      </span>
                    </div>
                    <div className="include">{msg.include}</div>
                    <div className="details">
                      <FontAwesomeIcon icon={faComment} />
                      {msg.twelveTimePlanDetail1}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faClipboardCheck} />
                      {msg.twelveTimePlanDetail2}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faEnvelope} />
                      {msg.twelveTimePlanDetail3}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faVideo} />
                      {msg.twelveTimePlanDetail4}
                    </div>
                  </Col>
                  <Col
                    md="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <div className="price-num d-flex justify-content-center">
                        {msg.dollarSign + twelveTimePlanPrice}
                      </div>
                      <div className="price-avg d-flex justify-content-center">
                        {msg.twelveTimePlanPriceP2}
                      </div>

                      <Button
                        className="btn-twelvetime rounded-pill d-flex justify-content-center"
                        onClick={() => togglePaymentModal(twelveTimePlanPrice)}
                      >
                        {msg.oneTimePlanBtn}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="pricing-card discount">
                <Row>
                  <Col md="9">
                    <div className="subtitle">{msg.discountSubtitle}</div>
                    <div className="details">
                      <FontAwesomeIcon icon={faHeart} />
                      {msg.discountDetail1}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faUser} />
                      {msg.discountDetail2}
                    </div>
                    <div className="details">
                      <FontAwesomeIcon icon={faFire} />
                      {msg.discountDetail3}
                    </div>
                  </Col>
                  <Col
                    md="3"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button
                      className="btn-discount rounded-pill d-flex justify-content-center"
                      href="https://calendly.com/irenewangcoaching/sample-session"
                      target="_blank"
                    >
                      {msg.discountBtn}
                    </Button>
                  </Col>
                </Row>
              </div>
              <Row>
                <div className="price-may-change">{msg.priceMayChange}</div>
              </Row>
            </Col>
          </Row>
          <Col md="1"></Col>
        </Container>
      </section>

      {/* --------Payment Modal------------------- */}
      <Modal
        className="payment-modal"
        size="lg"
        isOpen={isPaymentModalOpen}
        toggle={togglePaymentModal}
      >
        <ModalHeader toggle={togglePaymentModal}>
          <div className="subtitle">{msg.paymentModalTitle}</div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <span className="detail">{msg.amount}</span>
            {payNum > 0 && (
              <span className="amount-num">{msg.dollarSign + payNum}</span>
            )}
            <div className="payment-method detail">{msg.paymentModalP1}</div>
            <Row className="payment-method d-flex justify-content-center">
              <Button
                className={
                  "btn-payment venmo " +
                  (paymentMethod !== "VENMO" ? "light" : "")
                }
                onClick={() => setPaymentMethod("VENMO")}
              >
                {msg.venmo}
              </Button>
              <Button
                className={
                  "btn-payment wechat " +
                  (paymentMethod !== "WECHAT" ? "light" : "")
                }
                onClick={() => setPaymentMethod("WECHAT")}
              >
                {msg.wechat}
              </Button>
              <Button
                className={
                  "btn-payment paypal " +
                  (paymentMethod !== "PAYPAL" ? "light" : "")
                }
                onClick={() => setPaymentMethod("PAYPAL")}
              >
                {msg.paypal}
              </Button>
              <Button
                className={
                  "btn-payment other " +
                  (paymentMethod !== "OTHER" ? "light" : "")
                }
                onClick={() => setPaymentMethod("OTHER")}
              >
                {msg.otherPayment}
              </Button>
            </Row>
            {paymentMethod === "VENMO" && (
              <div className="venmo">
                <Row className="d-flex justify-content-center">
                  <div className="instruction venmo">{msg.venmo + ":"}</div>
                  <div className="detail">{msg.venmoPaymentP1}</div>
                  <img className="venmo-qr" src={venmoQR} alt="venmo-qr" />
                </Row>
              </div>
            )}
            {paymentMethod === "WECHAT" && (
              <div className="wechat">
                <Row className="d-flex justify-content-center">
                  <div className="instruction wechat">{msg.wechat + ":"}</div>
                  <div className="detail">{msg.wechatPaymentP1}</div>
                  <div className="detail">{msg.wechatPaymentP2}</div>

                  <img className="wechat-qr" src={wechatPayQR} alt="venmo-qr" />
                </Row>
              </div>
            )}
            {paymentMethod === "PAYPAL" && (
              <div className="paypal">
                <Row className="d-flex justify-content-center">
                  <div className="instruction paypal">{msg.paypal + ":"}</div>
                  <div className="detail">{msg.paypalPaymentP1}</div>
                  <div className="detail">{msg.paypalPaymentP2}</div>
                  <div className="detail">{msg.paypalPaymentP3}</div>
                </Row>
              </div>
            )}
            {paymentMethod === "OTHER" && (
              <div className="other">
                <Row className="d-flex justify-content-center">
                  <div className="instruction other">
                    {msg.otherPayment + ":"}
                  </div>
                  <div className="detail">{msg.otherPaymentP1}</div>
                  <div className="detail">{msg.otherPaymentP2}</div>
                  <div className="detail">{msg.otherPaymentP3}</div>
                </Row>
              </div>
            )}
            <div className="after detail">{msg.paymentModalP2}</div>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button onClick={togglePaymentModal}>{msg.close}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
