import React, { useState, useContext, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Button,
  Container,
} from "reactstrap";
import { translations } from "i18n/translations";
import { MyContext } from "context/mycontext";

// Navigation
export default function MyNavbar() {
  const { locale, setLocale } = useContext(MyContext);
  const msg = translations[locale] || translations.en;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  /* When user click language change, set locale and store new language in browser local storage */
  function changeLanguage(newlocale) {
    setLocale(newlocale);
    window.localStorage.setItem("YUYEWANG_WEB_LANG", JSON.stringify(newlocale));
  }

  /* Get language setting from local storage on first time page load. */
  useEffect(() => {
    const data = window.localStorage.getItem("YUYEWANG_WEB_LANG");
    if (!!data && data !== "undefined" && data !== locale) {
      setLocale(JSON.parse(data));
    }
  }, []);

  return (
    <>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
          <Container>
            <div className="brand-name">
              <span className="myname">{msg.brandName}</span>
            </div>
          </Container>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto " navbar>
            <NavItem>
              <NavLink href="/#anchorhome">{msg.navHome}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#anchorservice">{msg.navService}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#anchorabout">{msg.navAbout}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/pricing">{msg.navPricing}</NavLink>
            </NavItem>
            {locale !== "zh" && (
              <NavItem>
                <NavLink
                  onClick={() => {
                    changeLanguage("zh");
                  }}
                >
                  {msg.navChinese}
                </NavLink>
              </NavItem>
            )}
            {locale !== "en" && (
              <NavItem>
                <NavLink
                  onClick={() => {
                    changeLanguage("en");
                  }}
                >
                  {msg.navEnglish}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <NavbarText>
            <Button
              className="btn-book rounded-pill"
              href="https://calendly.com/irenewangcoaching"
              target="_blank"
            >
              {msg.bookNow}
            </Button>
          </NavbarText>
        </Collapse>
      </Navbar>
    </>
  );
}
