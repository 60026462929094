import React, { useContext } from "react";
import MyNavbar from "components/MyNavbar";
import Footer from "components/Footer";
import Pricing from "components/Pricing";
import { translations } from "i18n/translations";
import { MyContext } from "context/mycontext";

// Pricing page /pricing
export default function PricingPage() {
  const { locale } = useContext(MyContext);
  const msg = translations[locale] || translations.en;

  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);

  return (
    <>
      <div id="anchorhome"></div>
      <MyNavbar />
      <div className="main">
        <Pricing />
      </div>
      <Footer />
    </>
  );
}
