import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "css/index.css";
import reportWebVitals from "./reportWebVitals";
import HomePage from "views/HomePage";
import { MyContextProvider } from "context/mycontext";
import "bootstrap/dist/css/bootstrap.min.css";
import "css/App.css";
import PricingPage from "views/PricingPage";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <MyContextProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/pricing" exact element={<PricingPage />}></Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </MyContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
