import React, { useState, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { translations } from "i18n/translations";
import { MyContext } from "context/mycontext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faEarthAmerica,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faWeixin } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  const { locale } = useContext(MyContext);
  const msg = translations[locale] || translations.en;
  const [isWechatModalOpen, setIsWechatModalOpen] = useState(false);
  const toggleWechatModal = () => setIsWechatModalOpen(!isWechatModalOpen);

  return (
    <>
      <footer>
        <Container>
          <div id="anchorcontact"></div>
          <Row>
            <Col md="1"></Col>
            <Col md="3">
              <div>
                <span className="name">{msg.myName}</span>
              </div>
              <div>
                <span className="lifecoach">{msg.lifeCoach}</span>
              </div>
            </Col>
            <Col md="3">
              <div className="subtitle">{msg.footerContact}</div>
              <div>
                <a
                  className="social-link"
                  href="https://www.linkedin.com/in/yuyewang/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                  <span>{msg.linkedin}</span>
                </a>
                <div>
                  <a className="social-link" onClick={toggleWechatModal}>
                    <FontAwesomeIcon icon={faWeixin} />
                    <span>{msg.wechat}</span>
                  </a>
                </div>
                <div>
                  <a
                    className="social-link"
                    href="mailto:leaves1991@gmail.com?subject=Irene Wang Life Coaching"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>{msg.email}</span>
                  </a>
                </div>
                <a
                  className="social-link"
                  href="https://calendly.com/irenewangcoaching"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faCalendar} />
                  <span>{msg.calendar}</span>
                </a>
              </div>
            </Col>
            <Col md="3">
              <div className="subtitle">{msg.location}</div>
              <div className="details">
                <FontAwesomeIcon icon={faLocationDot} />
                {msg.city}
              </div>
              <div className="details">
                <FontAwesomeIcon icon={faEarthAmerica} />
                {msg.pst}
              </div>
            </Col>
            <Col md="2">
              <div className="details">{msg.cp}</div>
              <div className="details">{msg.rights}</div>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* --------Wechat Modal------------------- */}
      <Modal
        className="wechat-modal"
        isOpen={isWechatModalOpen}
        toggle={toggleWechatModal}
      >
        <ModalHeader toggle={toggleWechatModal}>
          <div className="subtitle">{msg.wechatModalTitle}</div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <div className="detail">{msg.wechatModalP1}</div>
            <div className="detail">{msg.wechatModalP2}</div>
            <div className="detail">{msg.wechatModalP3}</div>
            <Row className="d-flex justify-content-center">
              <div className="wechat-qr"></div>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleWechatModal}>{msg.close}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
