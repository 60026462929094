import React, { createContext, useState } from "react";

export const MyContext = createContext(undefined);

export const MyContextProvider = ({ children }) => {
  const [locale, setLocale] = useState("en");

  return (
    <MyContext.Provider value={{ locale, setLocale }}>
      {children}
    </MyContext.Provider>
  );
};
