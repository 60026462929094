import React, { useState, useContext } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { translations } from "i18n/translations";
import { MyContext } from "context/mycontext";
export default function About() {
  const { locale } = useContext(MyContext);
  const msg = translations[locale] || translations.en;

  return (
    <>
      <section className="about">
        <div id="anchorabout"></div>
        <Container>
          <Row>
            <Col md="4">
              <div className="about-me-img"></div>
            </Col>
            <Col md="8">
              <span className="section-subtitle theme-gradient">
                {msg.aboutSubtitle}
              </span>
              <div className="section-title">{msg.aboutTitle}</div>
              <div className="section-subtitle2 me-intro">{msg.meIntro}</div>
              <div className="section-subtitle2">{msg.aboutProfessional}</div>
              <ul className="areas">
                <li>{msg.me1}</li>
                <li>{msg.me2}</li>
                <li>{msg.me3}</li>
                <li>{msg.me4}</li>
              </ul>
              <div className="section-subtitle2">{msg.aboutPersonal}</div>
              <ul className="areas">
                <li>{msg.me5}</li>
                <li>{msg.me6}</li>
                <li>{msg.me7}</li>
              </ul>
              <div className="section-subtitle2">{msg.aboutLifecoach}</div>
              <ul className="areas">
                <li>{msg.me8}</li>
                <li>{msg.me9}</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
